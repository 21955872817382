<template>
  <div class="notification-box" :class="{flat: flat, [`type-${type}`]: type}">
    <div class="inner d-flex">
      <div class="pr-3">
        <v-icon>{{icons[type]}}</v-icon>
      </div>
      <div class="flex-grow-1">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationBox',
  props: {
    type: {
      default: 'info',
    },
    flat: {
      default: false,
    },
  },
  data: () => ({
    icons: {
      info: 'mdi-information',
      success: 'mdi-check-circle',
      access: 'mdi-lock',
      warning: 'mdi-alert-circle'
    },
    labels: {
      info: 'INFO',
      access: 'ACCESS',
    }
  }),
}
</script>

<style lang="scss">
.notification-box {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 -1px 2px -1px rgb(0 0 0 / 10%);
  background-color: #FFFFFF;

  &.flat {
    background-color: var(--grey-20);
    box-shadow: none;
  }
  .inner {
    padding: 8px 12px;
  }
  .label {
    font-size: 0.9rem;
  }
  &.type-info {
    border-left: 4px solid var(--orange);
    .v-icon {
      color: var(--orange);
    }
  }
  &.type-success {
    border-left: 4px solid #008656;
    .v-icon {
      color: #008656;
    }
  }
  &.type-warning {
    border-left: 4px solid rgb(230, 167, 0);
    .v-icon {
      color: rgb(230, 167, 0);
    }
  }
  &.type-error {
    border-left: 4px solid rgb(239, 68, 68);
    .v-icon {
      color: rgb(239, 68, 68);
    }
  }
}
</style>
